<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="blockquote-left" /> Gestión de Programas</h1>
        </div>
        <div class="card-body">
          <div class="container">
            <b-row>
              <b-col><ProgramConfigRegister /></b-col>
              <b-col class="mb-3">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="keyword"
                    placeholder="Buscar"
                    type="text"
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </div>
          <div class="container col-12">
            <div>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :keyword="keyword"
              >
                <template #cell(state)="row">
                  <b-badge pill :variant="returnColor(row.item.state)">{{
                    returnData(row.item.state)
                  }}</b-badge>
                </template>
                <template #cell(turn)="row">
                  <b-icon
                    type="button"
                    @click="toTurns(row.item.identifier)"
                    icon="sun"
                  />
                </template>
                <template #cell(modules)="row">
                  <b-icon
                    type="button"
                    @click="toModules(row.item.identifier)"
                    icon="box-seam"
                  />
                </template>
                <template #cell(actions)="row">
                  <b-icon-pencil-square
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="dateItem(row.item)"
                  ></b-icon-pencil-square>

                  <b-icon-trash
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="modalDelete(row.item)"
                  ></b-icon-trash>
                </template>
              </b-table>
              <b-pagination
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal scrollable centered v-model="show" title="Editar Programa">
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <b-alert
              :show="dismissCountDown"
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              <strong>Error. {{ error }},</strong> ocultando en
              {{ dismissCountDown }} segundos...
            </b-alert>
            <div class="form-group">
              <label for="validationServer01">Nombre</label>
              <b-form-input
                type="text"
                class="form-control"
                placeholder="Ingrese su nombre"
                v-model="data.name"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Descripción</label>
              <b-form-input
                type="text"
                class="form-control"
                v-model="data.description"
                placeholder="Ingrese descripción"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Acrónimo</label>
              <b-form-input
                type="text"
                class="form-control"
                v-model="data.acronym"
                placeholder="Ingrese acrónimo"
              />
            </div>
            <!--<div class="form-group">
              <label for="exampleInputPassword1">Imagen</label>
              <b-form-file
                type="file"
                browse-text="Buscar"
                placeholder="Seleccionar una imagen aqui"
                @change="previewImage"
                accept="image/*"
              />
            </div>-->
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveEditItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Editar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Mensaje alerta eliminacion-->

    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar a <b>{{ data.name }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { uuid } from "vue-uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import ProgramConfigRegister from "./Program-config-register.vue";
export default {
  components: { ProgramConfigRegister },
  data: () => ({
    selected: "",
    keyword: "",
    perPage: 10,
    currentPage: 1,
    imageUrl: "",
    imageData: null,
    picture: null,
    uploadValue: 0,
    dataDelete: "",
    error: "",
    dismissSecs: 2,
    dismissCountDown: 0,
    showDismissibleAlert: false,
    show: false,
    showDelete: false,
    data: {
      id: -1,
      identifier: "",
      index: null,
      name: "",
      acronym: "",
      description: "",
      state: "",
    },
    states: [
      { text: "Activo", value: "A" },
      { text: "Inactivo", value: "I" },
    ],
    estados: [
      { value: false, name: "Activo" },
      { value: true, name: "Inactivo" },
    ],
    areas: [
      { text: "Área Técnica", value: "T" },
      { text: "Área Administrativa", value: "A" },
    ],
    fields: [
      { key: "name", label: "Nombre" },
      { key: "acronym", label: "Acrónimo" },
      { key: "turn", label: "Turno" },
      { key: "modules", label: "Módulos" },
      { key: "state", label: "Estado" },
      { key: "actions", label: "Acciones" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.filter(
            (item) =>
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.acronym.toLowerCase().includes(this.keyword.toLowerCase())
          )
        : this.desserts;
    },
  },

  methods: {
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    //Se obtiene la terminacion segun el nombre
    getFileExtension(filename) {
      return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    },

    onUpload() {
      this.picture = null;
      /*var nameUrl =
        uuid.v1() + "." + this.getFileExtension(`${this.imageData.name}`);*/
      let nameUrl = null;
      if (this.data.image === "") {
        nameUrl = uuid.v1();
      } else {
        nameUrl = this.data.image;
      }
      const storageRef = firebase
        .storage()
        //uuid genera claves unicas
        .ref(nameUrl)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
      return nameUrl;
    },
    toModules(data) {
      this.$router.push("/program/" + data + "/modules");
    },
    toPrograms(data) {
      this.$router.push("/programs/" + data);
    },
    toTurns(data) {
      this.$router.push("/program/" + data + "/group");
    },
    returnData(data) {
      if (data === "A") return "Activo";
      else if (data === "I") return "Inactivo";
    },
    returnColor(data) {
      if (data === "A") return "success";
      else if (data === "I") return "danger";
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.error = data;
      this.dismissCountDown = this.dismissSecs;
    },
    exit() {
      this.show = false;
    },
    exitDelete() {
      this.showDelete = false;
      this.data.username = "";
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.name = data.name;
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/configuration/program", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            var obj = res.data[i];
            aux.push(obj);
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async dateItem(item) {
      this.show = true;
      let res = await this.$axios.get(
        "/configuration/program-edit?id=" + item.id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      );
      this.data = res.data;
    },
    async saveEditItem(item) {
      if (this.imageData != null) {
        var dato = this.onUpload();
        if (dato != null) {
          this.data.image = dato;
        }
      }
      this.show = true;
      if (item.id != -1) {
        try {
          await this.$axios
            .post("/configuration/program-edit", this.data, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.show = false;
              this.initialize();
            })
            .catch((e) => {
              this.showAlert(e.response.data.content);
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get("/configuration/program-delete?id=" + item.id, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error("Error!");
          console.log(e);
        }
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>