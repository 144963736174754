<template>
  <div id="ProgramConfigRegister">
    <button
      @click="addProgram()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Añadir Programa
    </button>
    <div>
      <b-modal scrollable centered v-model="show" title="Añadir programa">
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <b-alert
              :show="dismissCountDown"
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              <strong>Error. {{ error }},</strong> ocultando en
              {{ dismissCountDown }} segundos...
            </b-alert>
            <div class="form-group">
              <label for="validationServer01">Nombre</label>
              <b-form-input
                type="text"
                class="form-control"
                placeholder="Ingrese su nombre"
                v-model="data.name"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Área</label>
              <b-form-select
                class="form-control"
                v-model="data.area"
                :options="areas"
              ></b-form-select>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Descripción</label>
              <b-form-input
                type="text"
                class="form-control"
                v-model="data.description"
                placeholder="Ingrese descripción"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Acrónimo</label>
              <b-form-input
                type="text"
                class="form-control"
                v-model="data.acronym"
                placeholder="Ingrese acrónimo"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Imagen</label>
              <b-form-file
                type="file"
                browse-text="Buscar"
                placeholder="Seleccionar una imagen aqui"
                @change="previewImage"
                accept="image/*"
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
export default {
  name: "ProgramConfigRegister",
  data() {
    return {
      imageData: null,
      picture: null,
      uploadValue: 0,
      error: "",
      dismissSecs: 2,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      show: false,
      data: {
        name: "",
        description: "",
        area: "",
        image: null,
        acronym: "",
      },
      areas: [
        { text: "Área Técnica", value: "T" },
        { text: "Área Administrativa", value: "A" },
      ],
    };
  },
  methods: {
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    onUpload() {
      this.picture = null;
      let nameUrl = null;
      nameUrl = uuid.v1();
      const storageRef = firebase
        .storage()
        //uuid genera claves unicas
        .ref(nameUrl)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
      return nameUrl;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.error = data;
      this.dismissCountDown = this.dismissSecs;
    },
    exit() {
      this.show = false;
    },
    addProgram() {
      (this.data = {
        name: "",
        description: "",
        area: "T",
        image: null,
        acronym: "",
      }),
        (this.show = true);
    },
    async saveAddItem() {
      if (this.imageData != null) {
        var dato = this.onUpload();
        if (dato != null) {
          this.data.image = dato;
        }
      }
      await this.$axios
        .post("/configuration/program-register", this.data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.showAlert(e.response.data.content);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>